<template>
  <div class="container">
    <table-page data-name="orders"
                :tabList="tabList"
                ref="table"
                @on-success="fetchSuccess"
                :params="{activity_id:$route.query.id}"
                :search="search"
                :request="fetchGiftOrders">
      <template slot="button">

        <el-button icon="el-icon-download"
                   class="mr-15"
                   v-if="exportUrl"
                   @click="exportData">导出订单</el-button>
      </template>
      <el-table-column label="活动图片">
        <template slot-scope="scope">
          <TableImg :url="scope.row.gift_img_data ? scope.row.gift_img_data.url :''" />
        </template>

      </el-table-column>
      <el-table-column prop="no"
                       show-overflow-tooltip
                       label="订单号"></el-table-column>
      <el-table-column prop="gift_name"
                       show-overflow-tooltip
                       label="礼品名称"></el-table-column>
      <el-table-column prop="price"
                       show-overflow-tooltip
                       label="金额"></el-table-column>
      <el-table-column prop="num"
                       show-overflow-tooltip
                       label="数量"></el-table-column>

      <el-table-column prop="marketer_name"
                       show-overflow-tooltip
                       label="订单状态">
        <template slot-scope="scope">
          <span v-if="scope.row.status==='SUCCESS'|| scope.row.status==='PLACED'">
            <el-badge is-dot
                      type="success"
                      class="mr-15"></el-badge>
            <span v-if="scope.row.status==='SUCCESS'">已提货</span>
            <span v-if="scope.row.status==='PLACED'">已下单</span>
          </span>
          <span v-else-if="scope.row.status==='PAY_SUCCESS'"
                @click="pick({...scope.row,activity_id:$route.query.id})">
            <el-badge is-dot
                      type="warning"
                      class="mr-15"></el-badge>
            <span>未发货</span>
          </span>
          <span v-else>
            <el-badge is-dot
                      type="danger"
                      class="mr-15"></el-badge>
            <span v-if="scope.row.status==='PLACED_FAIL'">下单失败</span>
            <span v-if="scope.row.status==='PAY_FAIL'">支付失败</span>
            <span v-if="scope.row.status==='CANCEL'">已取消</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="wx_nickname"
                       show-overflow-tooltip
                       label="购买用户"></el-table-column>
      <el-table-column prop="userInfo"
                       show-overflow-tooltip
                       label="用户信息"></el-table-column>
      <el-table-column prop="marketer_name"
                       show-overflow-tooltip
                       label="关联员工"></el-table-column>
      <el-table-column prop="created_at"
                       show-overflow-tooltip
                       label="创建时间"></el-table-column>
    </table-page>
    <span slot="footer"
          class="dialog-footer"></span>

    <PickGood ref="pickgood"
              @success="$refs.table.fetch()" />
  </div>
</template>

<script>
import TableImg from '@/components/TableImg'
import TablePage from '@/components/TablePage'
import PickGood from './components/PickGood'
import { fetchGiftOrders } from '@/api/market'
export default {
  data () {
    return {
      exportUrl: '',
      fetchGiftOrders,
      dialogVisible: false,
      tabList: [{
        value: '',
        label: '全部'
      }, {
        value: 'PAY_SUCCESS',
        label: '未提货'
      }, {
        value: 'SUCCESS',
        label: '已提货'
      }],
      paramsObj: {},
      search: [{
        type: 'select',
        key: 'search_type',
        value: '',
        list: [
          {
            value: 'ORDER_NO',
            label: '订单编号'
          }, {
            value: 'WX_NICKNAME',
            label: '购买用户昵称'
          }, {
            value: 'MARKETER_NAME',
            label: '关联员工姓名'
          }, {
            value: 'MEMBER_NAME',
            label: '会员名'
          }, {
            value: 'MEMBER_TEL',
            label: '会员手机号'
          }, {
            value: 'MEMBER_ID_NUMBER',
            label: '会员身份证'
          }
        ]
      }, {
        type: 'input',
        key: 'search_keyword',
        value: ''
      }]
    }
  },
  components: {
    TablePage,
    PickGood,
    TableImg
  },
  methods: {
    exportData () {
      window.open(this.exportUrl, '_blank')
    },
    showTable (row, acId) {
      this.paramsObj = {
        activity_id: acId
      }
      this.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.table.fetch()
      })
    },
    pick (row) {
      this.$refs.pickgood.showModal(row)
    },
    fetchSuccess (e) {
      this.exportUrl = e.export_url
    }
  },
  mounted () { }
}
</script>

<style lang="less" scoped></style>
