import { render, staticRenderFns } from "./PickGood.vue?vue&type=template&id=5b873d27&scoped=true&"
import script from "./PickGood.vue?vue&type=script&lang=js&"
export * from "./PickGood.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b873d27",
  null
  
)

export default component.exports