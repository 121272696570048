<template>
  <el-dialog title="是否确认提货？"
             :close-on-click-modal='false'
             :visible.sync="dialogVisible"
             width="30%">
    <el-form :model="form"
             :rules="rules"
             ref="form"
             label-position="top"
             class="demo-ruleForm">
      <el-form-item label="姓名"
                    prop="name">
        <el-input v-model="form.name"
                  placeholder="请输入姓名"></el-input>
      </el-form-item>
      <el-form-item label="手机号"
                    prop="tel">
        <el-input v-model="form.tel"
                  placeholder="请输入手机号"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer"
          class="dialog-footer">
      <el-button type="primary"
                 @click="submitForm('form')">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { giftOrderVerify } from '@/api/market'
export default {
  data () {
    return {
      dialogVisible: false,
      form: {
        tel: '',
        name: ''
      },
      rules: {
        tel: [
          { required: true, message: '请输入手机号码', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ]
      }
    }
  },
  created () {

  },
  mounted () {

  },
  methods: {
    showModal (row) {
      this.dialogVisible = true
      this.form = {
        tel: '',
        name: '',
        activity_id: row.activity_id,
        order_id: row.id
      }
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm('确认提货', {
            type: 'warning'
          }).then(async () => {
            const res = await giftOrderVerify(this.form)
            if (res.meta.code === 0) {
              this.dialogVisible = false
              this.$notify({
                title: '成功',
                message: '提货成功',
                type: 'success'
              })
              this.$emit('success')
            } else {
              this.$message.error(res.meta.msg)
            }
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
</style>
